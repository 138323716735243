<template>
  <div
    class="p4b__parent"
  >
    <div v-if="showMessage">
      <div class="container">
        <div class="p4b__onboarding-form-content">
          <div class="p4b__toc-logo">
            <img
              :src="isMobile() ? logoMobImg : logoImg"
              alt="Logo"
              style="margin-bottom: 0;"
            >
          </div>
          <div class="p4b__onboarding-form-heading">
            <h4>Nirvana Probational Agent Onboarding Form</h4>
            <small>Submit by {{ dateFormatWithFormatParam(tokenDetail.expiresAt, 'DD MMM YYYY, hh:mma') }}</small>
          </div>

          <b-card class="p4b__onboarding-form-card">
            <b-card-text>
              <b-media
                left-align
                vertical-align="center"
                class="p4b__onboarding-form-media"
              >
                <template #aside>
                  <b-avatar
                    size="60px"
                    :src="upLineAgent.image"
                    :text="resolveAcronymName(upLineAgent.name)"
                  />
                </template>
                <h5>
                  Your upline
                </h5>
                <b-table-simple
                  borderless
                  responsive
                  class="d-none d-md-block mb-0 p4b__agent-details-cm-table"
                >
                  <b-tbody>
                    <b-tr>
                      <b-td
                        width="80px"
                        class="p4b__td-text-muted"
                        style="padding-left: 0 !important;"
                      >
                        Name
                      </b-td>
                      <b-td style="padding-left: 0 !important;">
                        {{ upLineAgent.name }}
                      </b-td>
                      <b-td
                        width="80px"
                        class="p4b__td-text-muted"
                        style="padding-left: 0 !important;"
                      >
                        Agency
                      </b-td>
                      <b-td style="padding-left: 0 !important;">
                        {{ upLineAgent.agencyName }}
                      </b-td>
                      <b-td />
                      <b-td />
                      <b-td />
                      <b-td />
                      <b-td />
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <b-table-simple
                  borderless
                  responsive
                  class="d-block d-md-none mb-0 p4b__agent-details-cm-table p4b__agent-details-table"
                >
                  <b-tbody>
                    <b-tr>
                      <b-td
                        width="80px"
                        class="text-muted"
                        style="padding-left: 0 !important;"
                      >
                        Name
                      </b-td>
                      <b-td style="text-wrap: wrap;">
                        {{ upLineAgent.name }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td
                        width="80px"
                        class="text-muted"
                        style="padding-left: 0 !important;"
                      >
                        Agency
                      </b-td>
                      <b-td style="text-wrap: wrap;">
                        {{ upLineAgent.agencyName }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-media>
            </b-card-text>
          </b-card>
          <b-card class="mb-3">
            <b-form @submit.prevent="submitFullForm">
              <validation-observer
                ref="agentOnboardingForm"
              >
                <b-row>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Your Name as per NRIC"
                      vid="name"
                      rules="required"
                    >
                      <b-form-group
                        label="Your Name as per NRIC*"
                        label-class="p4b__input-label"
                        label-for="h-name"
                      >
                        <b-form-input
                          id="h-name"
                          v-model="name"
                          class="mobile-input__value-adjust p4b__input-text"
                          placeholder="First name and last name"
                          :state="(errors.length > 0 || nameValidation) ? false : null"
                          name="name"
                          :disabled="$route.query.singpass"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="nameValidation"
                          class="text-danger"
                        >
                          {{ nameError }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="NRIC"
                      vid="nric"
                      rules="required"
                    >
                      <b-form-group
                        label="NRIC*"
                        label-class="p4b__input-label"
                        label-for="h-nric"
                      >
                        <b-form-input
                          id="h-nric"
                          v-model="nric"
                          class="mobile-input__value-adjust p4b__input-text"
                          :state="(errors.length > 0 || nricValidation) ? false : null"
                          name="nric"
                          :disabled="$route.query.singpass"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="nricValidation"
                          class="text-danger"
                        >
                          {{ nricError }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="md-6">
                    <validation-provider
                      #default="{ errors }"
                      name="Sex"
                      vid="gender"
                      rules="required"
                    >
                      <b-form-group
                        label="Sex*"
                        label-class="p4b__input-label"
                        label-for="h-sex"
                        :state="(errors.length > 0 || genderValidation) ? false : null"
                      >
                        <v-select
                          id="h-sex"
                          v-model="gender"
                          label="title"
                          class="mobile-input__value-adjust p4b__input-select"
                          placeholder="Select from list"
                          :options="genderOptions"
                          :reduce="title => title.code"
                          :clearable="false"
                          @input="genderValidation == true ? genderValidation = false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="genderValidation"
                          class="text-danger"
                        >
                          {{ genderError }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="md-6">
                    <validation-provider
                      #default="{ errors }"
                      name="Race"
                      vid="race"
                      rules="required"
                    >
                      <b-form-group
                        label="Race*"
                        label-class="p4b__input-label"
                        label-for="h-sex"
                        :state="(errors.length > 0 || raceValidation) ? false : null"
                      >
                        <v-select
                          id="h-sex"
                          v-model="race"
                          label="title"
                          class="mobile-input__value-adjust p4b__input-select"
                          placeholder="Select from list"
                          :options="raceOptions"
                          :reduce="title => title.code"
                          :clearable="false"
                          @input="raceValidation == true ? raceValidation = false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="raceValidation"
                          class="text-danger"
                        >
                          {{ raceError }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Date Of Birth"
                      vid="birthDate"
                      rules="required"
                    >
                      <b-form-group
                        label="Date Of Birth*"
                        label-for="h-birth-date"
                        label-class="p4b__input-label"
                      >
                        <b-input-group class="p4a__flat-pickr-wrapper">
                          <flat-pickr
                            id="h-birth-date"
                            v-model="birthDate"
                            class="form-control flat-pickr-group mobile-input__value-adjust"
                            :config="flatPickrConfig"
                            @input="birthDateValidation == true ? birthDateValidation = false : null"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer p4b__text-base-light"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="birthDateValidation"
                          class="text-danger"
                        >
                          {{ birthDateError }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    class="d-flex"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Mobile"
                      vid="countryCode"
                      rules="required"
                    >
                      <b-form-group
                        label="Country Code"
                        label-for="h-country-code"
                        label-class="p4b__input-label"
                        class="mr-1"
                      >
                        <b-form-input
                          id="h-country-code"
                          v-model="countryCode"
                          class="mobile-input__value-adjust p4b__input-text"
                          placeholder="+ 65"
                          name="countryCode"
                          readonly
                          :state="(errors.length > 0 || countryCodeValidation) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="countryCodeValidation"
                          class="text-danger"
                        >
                          {{ countryCodeError }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                    <validation-provider
                      #default="{ errors }"
                      name="Mobile No."
                      vid="mobileNo"
                      rules="required|digits:8"
                      class="w-100"
                    >
                      <b-form-group
                        label="Mobile No."
                        label-for="h-mobile-no"
                        label-class="p4b__input-label"
                      >
                        <b-form-input
                          id="h-mobile-no"
                          v-model="mobileNo"
                          class="mobile-input__value-adjust p4b__input-text"
                          placeholder="Enter 8 digits"
                          name="mobileNo"
                          :state="(errors.length > 0 || mobileNoValidation) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="mobileNoValidation"
                          class="text-danger"
                        >
                          {{ mobileNoError }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Email Address"
                      vid="emailAddress"
                      rules="required|email"
                    >
                      <b-form-group
                        label="Email Address*"
                        label-class="p4b__input-label"
                        label-for="h-email-address"
                      >
                        <b-form-input
                          id="h-email-address"
                          v-model="emailAddress"
                          class="mobile-input__value-adjust p4b__input-text"
                          placeholder="johndoe@gmail.com"
                          :state="(errors.length > 0 || emailAddressValidation) ? false : null"
                          name="emailAddress"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="emailAddressValidation"
                          class="text-danger"
                        >
                          {{ emailAddressError }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12">
                    <b-form-checkbox
                      v-model="agreeWithToc"
                      class="p4b__checkbox-input"
                    >
                      I have read and agree to Nirvana’s <strong class="p4b__text-base-light">Privacy Policy</strong>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-form>
          </b-card>
        </div>
      </div>
      <div class="p4b__btn-section">
        <b-button
          class="p4b__btn-common"
          :disabled="!agreeWithToc || !showMessage"
          block
          @click="submitFullForm"
        >
          Submit
        </b-button>
      </div>
      <div
        v-show="$store.state.appConfig.showLoader"
        class="p4b__preloader"
      >
        <div class="p4b__circle-container">
          <div class="p4b__circle-progress" />
        </div>
      </div>
    </div>
    <div v-if="expiredLink">
      <div class="container">
        <div class="p4b__onboarding-form-content">
          <div class="p4b__toc-logo">
            <img
              :src="isMobile() ? logoWhiteMobImg : logoWhiteLargeImg"
              alt="Logo"
            >
          </div>
          <div class="p4b__onboarding-form-link-heading">
            <feather-icon
              icon="LinkIcon"
              size="80"
            />
            <h4>This invite has expired</h4>
            <p>Your onboarding link has expired. Please request a new link from your upline to continue the process.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BCard, BCardText, BAvatar, BMedia, BRow, BCol, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BFormCheckbox, VBTooltip, BTableSimple, BTbody, BTr, BTd,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { required } from '@validations'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BAvatar,
    BMedia,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormCheckbox,
    flatPickr,
    vSelect,
    BTableSimple,
    BTbody,
    BTr,
    BTd,

    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/frontend/p4b/logo-blue.png'),
      // eslint-disable-next-line global-require
      logoMobImg: require('@/assets/images/frontend/p4b/logo-blue-mob.png'),
      // eslint-disable-next-line global-require
      manAvatar: require('@/assets/images/frontend/p4b/man-avatar.png'),
      // eslint-disable-next-line global-require
      closeAffordable: require('@/assets/images/frontend/p4b/icons/close-affordable.png'),
      // eslint-disable-next-line global-require
      singPassLogo: require('@/assets/images/frontend/p4b/singpass-logo.png'),
      // eslint-disable-next-line global-require
      logoWhiteLargeImg: require('@/assets/images/frontend/p4b/logo-white-large.png'),
      // eslint-disable-next-line global-require
      logoWhiteMobImg: require('@/assets/images/frontend/p4b/logo-white-mob.png'),
      genderOptions: [
        { title: 'MALE', code: 'MALE' },
        { title: 'FEMALE', code: 'FEMALE' },
        { title: 'N/A', code: 'N/A' },
      ],
      raceOptions: [
        { title: 'CHINESE', code: 'CHINESE' },
        { title: 'MALAY', code: 'MALAY' },
        { title: 'INDIAN', code: 'INDIAN' },
        { title: 'N/A', code: 'N/A' },
        { title: 'OTHERS', code: 'OTHERS' },
      ],
      name: '',
      nameValidation: false,
      nameError: 'Valid name is required',
      nric: '',
      nricValidation: false,
      nricError: 'Valid NRIC is required',
      gender: '',
      genderValidation: false,
      genderError: 'Valid Sex is required',
      race: '',
      raceValidation: false,
      raceError: 'Valid Race is required',
      birthDate: '',
      birthDateValidation: false,
      birthDateError: 'Valid birth date is required',
      countryCode: '+65 ',
      countryCodeValidation: false,
      countryCodeError: 'Valid Country Code is required',
      mobileNo: '',
      mobileNoValidation: false,
      mobileNoError: 'Valid Mobile No. is required',
      emailAddress: '',
      emailAddressValidation: false,
      emailAddressError: 'Valid Email Address is required',
      agreeWithToc: false,

      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      showMessage: false,
      expiredLink: false,
      tokenDetail: {},
      upLineAgent: {},

      // validation rules
      required,
    }
  },

  beforeMount() {
    document.body.classList.add('p4b__bg')
    localStorage.removeItem('onboardingToken')
    const { token } = this.$route.params
    if (token) {
      this.$http.get('front-end/agent/onboarding/validate/token', { params: { token } })
        .then(response => {
          if (response.data.success) {
            if (response.data.validToken) {
              this.showMessage = true
              this.tokenDetail = response.data.token || {}
              this.upLineAgent = response.data.agent
              if (this.$route.query.singpass) {
                const userInfo = JSON.parse(localStorage.getItem('singpassUserInfo'))
                if (userInfo) {
                  this.name = userInfo.name ? userInfo.name.value : ''
                  this.nric = userInfo.uinfin ? userInfo.uinfin.value : ''
                  this.gender = userInfo.sex ? userInfo.sex.desc : ''
                  this.race = userInfo.race ? userInfo.race.desc : ''
                  this.birthDate = (userInfo.dob && userInfo.dob.value) ? moment(userInfo.dob.value).utc().format('DD/MM/YYYY') : ''
                }
              }
            } else {
              document.body.classList.remove('p4b__bg')
              document.body.classList.add('p4b__bg-black')
              this.expiredLink = true
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },

  methods: {
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    submitFullForm() {
      this.$refs.agentOnboardingForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('name', this.name)
          formData.append('nric', this.nric)
          formData.append('sex', this.gender)
          formData.append('race', this.race)
          formData.append('dob', this.birthDate)
          formData.append('countryCode', this.countryCode)
          formData.append('contact', this.mobileNo)
          formData.append('email', this.emailAddress)
          formData.append('token', this.$route.params.token)
          formData.append('link', this.tokenDetail._id)
          this.$http.post('front-end/agent/onboarding/submit/final', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: '<span class="p4b__swal-title">Submission successful</span>',
                  html: '<p class="p4b__swal-subtitle">Your form has been successfully submitted. Your upline agent will be notified shortly. <br><br> <span class="p4b__swal-subtitle-bottom">Please check your email for instructions on how to access the Agent Sales tablet app.</span></p>',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/frontend/p4b__success_icon.svg'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  allowOutsideClick: false,
                  reverseButtons: false,
                  confirmButtonText: 'Ok, got it',
                  customClass: {
                    container: 'p4b__onboarding-form-swal',
                    confirmButton: 'btn p4b__swal-common-btn',
                  },
                  buttonsStyling: false,
                  width: '428px',
                })
                  .then(() => {
                    this.$router.push({ name: 'appointments-front-end' })
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'email') {
                    this.emailAddressError = validationError.msg
                    this.emailAddressValidation = true
                  } else if (validationError.param === 'contact') {
                    this.mobileNoError = validationError.msg
                    this.mobileNoValidation = true
                  } else if (validationError.param === 'nric') {
                    this.nricError = validationError.msg
                    this.nricValidation = true
                  } else if (validationError.param === 'sex') {
                    this.genderError = validationError.msg
                    this.genderValidation = true
                  } else if (validationError.param === 'race') {
                    this.raceError = validationError.msg
                    this.raceValidation = true
                  } else if (validationError.param === 'dob') {
                    this.birthDateError = validationError.msg
                    this.birthDateValidation = true
                  }
                })
                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
